<template>
    <div v-if="showSystemErrors|| isLoaded" class="z-index-100 u-bg-color-full-screen d-flex justify-content-center text-center align-items-center">
        <div class="pt-5">
            <div v-if="showSystemErrors" class="qa-system-error text-left">
                <p class="u-color-white u-font-size-24 u-fw-regular">{{systemErrorData.message.title}}</p>
                <p class="u-color-white u-font-size-24" v-html="systemErrorData.message.body"></p>
                <p class="u-color-white u-font-size-24">{{systemErrorData.message.greeting}}</p>
                <p class="u-color-white m-0 u-font-size-24">{{systemErrorData.message.emailMessage}}</p> 
                <a class="u-color-white u-font-size-24" :href="`mailto:${systemErrorData.message.email}`">{{systemErrorData.message.email}}</a>
            </div>
            <div v-else-if="isLoaded" class="qa-access-denied">
                <span class="qa-access-denied-title u-text-figure u-color-white">{{ SHARED.AccessDenied.Title }}</span>
                <h3 class="qa-access-denied-description u-color-white pt-4">{{ SHARED.AccessDenied.Description }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '../../services/UserService'
import { SHARED } from "../../constants/EdumsSharedConstants.json"; 

export default {
    name: 'ErrorPage',
    props: {
        systemErrorData: {
            type: Object,
            default() {
                return null
            }
        }
    }, 
    data() {
        return { 
            SHARED,
            timeout: null,  
            userService: new UserService(),
            isLoaded: false
        }
    }, 
    computed: {
        showSystemErrors() {
            return this.systemErrorData !== null && this.systemErrorData.message !== null
        }
    },
    watch: {
        systemErrorData: {
            handler() {
                if(this.showSystemErrors === false && this.isLoaded === false) { 
                    setTimeout(() => {
                        this.isLoaded = true
                    }, 500);
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {  
        this.$nextTick(() => {            
            this.timeout = setTimeout(() => {
                this.userService.signOut()
            }, 6000)
        })
    }, 
    beforeDestroy() {
        clearTimeout(this.timeout)
    } 
}
</script>
 
