let notificationId = 1


const toastNotificationModule = {
    namespaced: true,
    state() {
        return {
            toastNotifications: []
        }
    },
    mutations: {
        push(state, toastNotification) { 
            notificationId += 1 
            toastNotification.id = notificationId 

            const checkErrorMessage = state.toastNotifications.filter(currentNotification => 
                currentNotification.type === 'error' && currentNotification.message === toastNotification.message
            ) 

            const checkSuccessMessage = state.toastNotifications.filter(currentNotification => 
                currentNotification.type === 'success' && currentNotification.message === toastNotification.message
            ) 

            if(checkErrorMessage && checkErrorMessage.length > 0 || checkSuccessMessage && checkSuccessMessage.length > 0) return 

            state.toastNotifications.push(toastNotification)            
        },
        delete(state, toastNotificationToRemove) {
            state.toastNotifications = state.toastNotifications.filter(
                toastNotification => toastNotification.id !== toastNotificationToRemove.id
            )
        }
    },
    actions: {
        add({ commit }, toastNotifications) { 
            commit('push', toastNotifications)
        },
        remove({ commit }, toastNotificationToRemove) {
            commit('delete', toastNotificationToRemove) 
        }
    }
}

export default toastNotificationModule
