import axios from 'axios'

const CancelToken = axios.CancelToken
let cancel

// this method is used to get courses, edudex lists, accounts, legal companies, providers
export function getItems(api, urlPath, page, rowsPerPage, orderBy, filter, cancelPreviousRequest, expand = null) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    const offset = page * rowsPerPage - rowsPerPage
    let url = `${api}${urlPath}?offset=${offset}&limit=${rowsPerPage}`

    if (orderBy !== null && orderBy !== "") {
        url = url.concat(`&orderBy=${orderBy}`)
    }

    if (filter !== null && filter !== "") {
        url = url.concat(`&filter=${filter}`)
    }

    if(expand !== null && expand !== "")
    {
        url = url.concat(`&expand=${expand}`)
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

export function patchItem(api, urlPath, id, item, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${api}${urlPath}/${id}`,
            cancelToken: new CancelToken((c) => {
                cancel = c
            }),
            data: item
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

export function postItem(api, urlPath, item, cancelPreviousRequest, responseTypeRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${api}${urlPath}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
            data: item,
            responseType: responseTypeRequest
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function deleteItem(api, urlPath, cancelPreviousRequest, payload) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url: `${api}${urlPath}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
            data: payload
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

//TODO MD: ED-14754 Use generic deleteItem instead
export function deleteAssortedProducts(api, urlPath, assProdObj, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url: `${api}${urlPath}`,
            data: assProdObj,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
        })
            .then((resp) => {
                resolve(resp.data)
            })
            .catch((resp) => {
                reject(resp)
            })
    })
}

const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] }
        }),
        {}
    )

export function getEnumList(
    api,
    urlPath,
    parentEnumType,
    childEnumType,
    parentValue,
    cancelPreviousRequest
) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    if (!parentEnumType) return null

    const subPath =
        childEnumType && parentValue
            ? `${parentEnumType}/child/${childEnumType}/${parentValue}`
            : `${parentEnumType}`

    const url = `${api}${urlPath}/${subPath}`

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                const keysMap = {
                    key: 'value',
                    description: 'displayText'
                }
                const renamedResult = response.data.map((obj) => renameKeys(keysMap, obj))
                resolve(renamedResult)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

// this method is used to get courses & orders from search index
export function getItemsFromSearchIndex(api, urlPath, lastSegmentOfPath, filter) {
    let url = `${api}${urlPath}`

    if (lastSegmentOfPath != null) {
        url = url.concat(`/${lastSegmentOfPath}`)
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
            data: filter
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

// this method is used to get specific course, order, provider, edudex-import, legal company, provider contract
export function getItemById(api, urlPath, id, expandProperties, cancelPreviousRequest) { 
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    if (!id) {
        throw new TypeError('Invalid parameters (id)')
    }

    let url = `${api}${urlPath}/${id}`

    if (expandProperties !== null) {
        expandProperties === 'productSubSegments' ? url = url.concat(`?expandProperties=${expandProperties}`) : url = url.concat(`?expand=${expandProperties}`)
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}
// this method is used for requests that can't be cancelled (e.g. to get the user consent)
export function getItemByIdNoCancel(api, urlPath, id) {

    if (!id) {
        throw new TypeError('Invalid parameters (id)')
    }

    const url = `${api}${urlPath}/${id}`

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

// this method is used to put course
export function putItem(api, urlPath, id, item, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'put',
            url: `${api}${urlPath}/${id}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
            data: item
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

export function getItem(api, urlPath, cancelPreviousRequest, responseType, params = null) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${api}${urlPath}`,
            params: params,
            responseType: responseType,
            cancelToken: new CancelToken((c) => {
                cancel = c
            })
        })
        .then((response) => {
            resolve(response.data)
        })
        .catch((response) => {
            reject(response)
        })
    })
}

export default { postItem, patchItem, putItem, getItems, getItemById, getItemByIdNoCancel, deleteItem, deleteAssortedProducts, getEnumList, getItemsFromSearchIndex, getItem }