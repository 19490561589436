import get from 'lodash/get'
import { getItemById, putItem, postItem, patchItem } from "@/../../shared/api/SharedClient"

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

import {
    setProperty,
    setPath,
    addItem,
    removeItem,
    addEmptyContentBlock,
    setContentBlockProperty,
    setInvoiceItemsProperty,
    setStartMomentItemsProperty,
    setModuleItemsProperty,
    setEventItemsProperty,
    getArrayItems,
    setCourseNotificationError,
    setIsVisibleInStartMoments,
    sortStartMoments,
    setCountryVatPercentageOptions,
    setVatPercentageOptionsForSpecificInvoiceItems,
    setDefaultSelectedTypesInContentItem,
    setIsStandardPricingVisible,
    updateEnumsBeforeSave,
    updateInhoundBeforeSave,
    initializeEmptyProperties,
    getEmptyInvoiceItem,
    updateSubSegmentsBeforeSave
} from '@/utils/vuexFunctionHelper'

import { defaultEditSuccessMessage, assortedProductSuccessAction } from '../../../../shared/constants/validationMessageHelper'

import {
    combineEventDateTime,
    formatEventDateTimeToLocal,
    formatToUtcBeforeSave,
    formatFromUtcToLocalDate
} from '@/utils/dateTimeHelper'

const courseModule = {
    namespaced: true,
    state() {
        return {
            course: {},
            courseProperties: {}
        }
    },
    getters: {
        getInvoiceItems: (state) => getArrayItems(state, 'course.learningMethod.costs.details[0].invoiceItems'),
        getStartMomentItems: (state) => {
            return getArrayItems(state, 'course.learningMethod.planning.startMoments')
        },
        getContentItem: (state) => getArrayItems(state, 'course.content[0]')
    },
    mutations: {
        resetCourseState(state) {
            state.course = {}
        },
        setCourse: setProperty('course'),
        // general info & learning method
        setProductType: setPath(['course', 'productType']),
        setCourseName: setPath(['course', 'name']),
        setCourseShortName: setPath(['course', 'shortName']),
        setCourseStartDate: setPath(['course', 'startDate']),
        setCourseEndDate: setPath(['course', 'endDate']),
        setProviderLmsLink: setPath(['course', 'providerLmsLink']),
        setExternalId: setPath(['course', 'externalId']),
        setCourseProviderId: setPath(['course', 'providerId']),
        setCommercialPartner: setPath(['course', 'commercialPartner']),
        setLearningMethodType: setPath(['course', 'learningMethod', 'type']),
        setLearningMethodFormat: setPath([
            'course',
            'learningMethod',
            'format'
        ]),
        setLearningMethodLanguage: setPath([
            'course',
            'learningMethod',
            'language'
        ]),
        setLearningMethodLanguageMaterial: setPath([
            'course',
            'learningMethod',
            'languageMaterial'
        ]),
        setLearningMethodContactSessions: setPath([
            'course',
            'learningMethod',
            'contactSessions'
        ]),
        setLearningMethodStudyLoadValue: setPath([
            'course',
            'learningMethod',
            'studyLoad',
            'value'
        ]),
        setLearningMethodStudyLoadUnit: setPath([
            'course',
            'learningMethod',
            'studyLoad',
            'unit'
        ]),
        setLearningMethodDurationValue: setPath([
            'course',
            'learningMethod',
            'duration',
            'value'
        ]),
        setLearningMethodDurationUnit: setPath([
            'course',
            'learningMethod',
            'duration',
            'unit'
        ]),
        setEducationLevelCode: setPath(['course', 'educationLevelCode']),
        setIsProfessionalCourse: setPath(['course', 'isProfessionalCourse']),
        // extra options
        setRequirementsType: setPath(['course', 'requirements', 'type']),
        setRequirementsDescription(state, { description }) {
            const descriptionItems = getArrayItems(
                state,
                'course.requirements.description'
            )

            descriptionItems[0] = description
        },
        setCompetences(state, { competences }) {
            state.course.competences = competences
        },
        setCompetencesRijksoverheid(state, { competencesRijksoverheid }) {
            state.course.competencesRijksoverheid = competencesRijksoverheid
        },
        setSubsidies(state, { subsidies }) {
            state.course.subsidies = subsidies
        },
        setTargetSector(state, { targetSector }) {
            state.course.targetSector = targetSector
        },
        setTargetSectorRijksoverheid(state, { targetSectorRijksoverheid }) {
            state.course.targetSectorRijksoverheid = targetSectorRijksoverheid
        },
        setSearchWords(state, { searchword }) {
            state.course.searchword = searchword
        },
        // diploma
        setDiploma: setPath(['course', 'diplomaType']),
        setAccreditationType: setPath(['course', 'accreditation', 'type']),
        setAccreditationPoints: setPath(['course', 'accreditation', 'points']),
        setAccreditationValidFrom: setPath([
            'course',
            'accreditation',
            'validFrom'
        ]),
        setAccreditationValidTo: setPath([
            'course',
            'accreditation',
            'validTo'
        ]),
        setCroho: setPath(['course', 'croho']),
        setCrebo: setPath(['course', 'crebo']),
        setCreditType: setPath(['course', 'credit', 'type']),
        setCreditValue: setPath(['course', 'credit', 'value']),
        // content
        setContentDescription(state, { contentDescription }) {
            state.course.content[0].description = contentDescription
        },
        setContentDescriptionHtml(state, { contentDescriptionHtml }) {
            state.course.content[0].descriptionHtml = contentDescriptionHtml
        },
        setContentIntro(state, { contentIntro }) {
            state.course.content[0].intro = contentIntro
        },
        setContentIntroHtml(state, { contentIntroHtml }) {
            state.course.content[0].introHtml = contentIntroHtml
        },
        setSelectedContentIntro(state, { selectedIntro }) {
            state.course.content[0].selectedIntro = selectedIntro
        },
        setContentObjectiveHtml(state, { contentObjectiveHtml }) {
            state.course.content[0].blocks[0].bodyHtml = contentObjectiveHtml
        },
        setContentObjective(state, { contentObjective }) {
            state.course.content[0].blocks[0].body = contentObjective
        },
        setContentBlockType(state, { index, type, displayValue } ) {  
            setContentBlockProperty(state, index, 'type', type, displayValue)
        },
        setHeading(state, { index, heading }) {
            setContentBlockProperty(state, index, 'heading', heading)
        },
        setContentBlockBody(state, { index, body }) {
            setContentBlockProperty(state, index, 'body', body)
        },
        setContentBlockBodyHtml(state, { index, bodyHtml }) {
            setContentBlockProperty(state, index, 'bodyHtml', bodyHtml)
        },
        setSelectedContentBlockBody(state, { index, selectedBody }) {
            setContentBlockProperty(state, index, 'selectedBody', selectedBody)
        },
        setStartMoments(state, { startMoments }) {
            if (state.course.learningMethod.planning?.startMoments) {
                state.course.learningMethod.planning.startMoments = startMoments
            }
        },
        addContentBlock(state) {
            const contentBlocks = getArrayItems(
                state,
                'course.content[0].blocks'
            )
            const emptyContentBlock = {
                heading: '',
                type: '',
                body: '',
                bodyHtml: '',
                selectedBody: 'text'
            }
            addItem(contentBlocks, 'heading', emptyContentBlock)
        },
        removeContentBlock(state, index) {
            const contentBlocks = getArrayItems(
                state,
                'course.content[0].blocks'
            )
            removeItem(contentBlocks, index, 0)
        },
        // invoice item
        setCostType(state, { startMomentIndex, index, costType }) {
            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'costType',
                costType
            )
        },
        setPriceBeforeTax(state, { startMomentIndex, index, priceBeforeTax }) {
            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'priceBeforeTax',
                priceBeforeTax
            )
        },
        setCountryAndVatPercentageOptions(
            state,
            {
                startMomentIndex,
                index,
                selectedCountry,
                taxRates,
                resetVatPercentage
            }
        ) {
            // get an array from state with specific attributes
            const path =
                startMomentIndex === null
                    ? 'course.learningMethod.costs.details[0].invoiceItems'
                    : `course.learningMethod.planning.startMoments[${startMomentIndex}].invoiceItems`
            const invoiceItems = get(state, path, null)

            if (invoiceItems === null || invoiceItems.length <= index) return

            invoiceItems[index].vatCountry = selectedCountry

            if (invoiceItems[index].vatCountry) {
                // reset vatPercentageOptions array & vat percentage when country value changes
                if (resetVatPercentage) {
                    invoiceItems[index].vatPercentage = null
                }
                invoiceItems[index].vatPercentageOptions = []

                // based on the selected country, recreate country-specific vatPercentageOptions for the BTW select dropdown in invoiceItem component
                taxRates.reduce((acc, taxRate) => {
                    if (taxRate !== null) {
                        const newOption = {
                            displayText: `${taxRate}%`,
                            value: taxRate
                        }

                        invoiceItems[index].vatPercentageOptions = [...invoiceItems[index].vatPercentageOptions, newOption]
                    }
                    return acc
                }, [])
            }
        },
        setVatPercentage(state, { startMomentIndex, index, vatPercentage }) {

            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'vatPercentage',
                vatPercentage
            )

        },
        setVatAmount(state, { startMomentIndex, index }) {
            const path =
                startMomentIndex === null
                    ? `course.learningMethod.costs.details[0].invoiceItems[${index}]`
                    : `course.learningMethod.planning.startMoments['${startMomentIndex}'].invoiceItems['${index}']`
            const invoiceItem = get(state, path, null)
            const vatPercentage = invoiceItem.vatPercentage
            const priceBeforeTax = invoiceItem.priceBeforeTax
            const vatAmount = (vatPercentage / 100) * priceBeforeTax
            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'vatAmount',
                vatAmount.toFixed(2).replace(/(\.0+|0+)$/, '')
            )
        },
        setVatExemptAmount(
            state,
            { startMomentIndex, index, vatExemptAmount }
        ) {
            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'vatExemptAmount',
                vatExemptAmount
            )
        },
        setVatExemptAmountVat(
            state,
            { startMomentIndex, index, vatExemptAmountVAT }
        ) {
            setInvoiceItemsProperty(
                state,
                startMomentIndex,
                index,
                'vatExemptAmountVAT',
                vatExemptAmountVAT
            )
        },
        addInvoiceItem(state, { startMomentIndex }) {
            const emptyInvoiceItem = {
                quantity: 1, // always 1
                priceBeforeTax: null,
                vatAmount: null,
                vatCountry: 'NL', // default value is Nederland
                vatPercentage: null,
                vatPercentageOptions: [], // add this extra property in the front-end to populate vat percentage options based on selected country
                vatExemptAmount: 0,
                vatExemptAmountVAT: 0,
                costType: null,
                isOptional: false
            }
            const path =
                startMomentIndex === null
                    ? 'course.learningMethod.costs.details[0].invoiceItems'
                    : `course.learningMethod.planning.startMoments['${startMomentIndex}'].invoiceItems`

            const invoiceItems = get(state, path, null)

            addItem(invoiceItems, 'costType', emptyInvoiceItem)
        },
        removeInvoiceItem(state, { index, startMomentIndex }) {
            const path =
                startMomentIndex === null
                    ? 'course.learningMethod.costs.details[0].invoiceItems'
                    : `course.learningMethod.planning.startMoments['${startMomentIndex}'].invoiceItems`
            const invoiceItems = get(state, path, null)
            const defaultIndex = startMomentIndex === null ? 1 : 0
            removeItem(invoiceItems, index, defaultIndex)
        },
        // Start moments
        setStartMomentExternalId(state, { index, startMomentExternalId }) {
            setStartMomentItemsProperty(
                state,
                index,
                'externalId',
                startMomentExternalId
            )
        },
        setStartMomentType(state, { index, startMomentType }) {
            setStartMomentItemsProperty(state, index, 'type', startMomentType)
        },
        setStartMomentErrors(state, { startMomentIndex, hasErrors }) {
            if (!startMomentIndex) return
            state.course.learningMethod.planning.startMoments[startMomentIndex].hasErrors = hasErrors
        },
        setStartMomentCity(state, { index, city }) {
            setStartMomentItemsProperty(state, index, 'city', city)
        },
        setStartMomentStreetName(state, { index, streetName }) {
            setStartMomentItemsProperty(state, index, 'streetName', streetName)
        },
        setStartMomentHouseNumber(state, { index, houseNumber }) {
            setStartMomentItemsProperty(state, index, 'houseNumber', houseNumber)
        },
        setStartMomentPostcode(state, { index, postcode }) {
            setStartMomentItemsProperty(state, index, 'postcode', postcode)
        },
        setStartMomentFullDate(state, { index, fullDate }) {
            setStartMomentItemsProperty(
                state,
                index,
                'startDate.fullDate',
                fullDate
            )
        },
        setCutOffDate(state, { index, cutOffDate }) {
            setStartMomentItemsProperty(
                state,
                index,
                'cutOffDate',
                cutOffDate
            )
        },
        setStartMomentDay(state, { index, day }) {
            setStartMomentItemsProperty(state, index, 'startDate.day', day)
        },
        setStartMomentMonth(state, { index, month }) {
            setStartMomentItemsProperty(state, index, 'startDate.month', month)
        },
        setStartMomentYear(state, { index, year }) {
            setStartMomentItemsProperty(state, index, 'startDate.year', year)
        },
        setMinParticipants(state, { index, minParticipants }) {
            if (minParticipants === '') {
                setStartMomentItemsProperty(
                    state,
                    index,
                    'minParticipants',
                    null
                )
            } else {
                setStartMomentItemsProperty(
                    state,
                    index,
                    'minParticipants',
                    minParticipants
                )
            }
        },
        setMaxParticipants(state, { index, maxParticipants }) {
            if (maxParticipants === '') {
                setStartMomentItemsProperty(
                    state,
                    index,
                    'maxParticipants',
                    null
                )
            } else {
                setStartMomentItemsProperty(
                    state,
                    index,
                    'maxParticipants',
                    maxParticipants
                )
            }
        },
        setStartGuaranteed(state, { index, startGuaranteed }) {
            setStartMomentItemsProperty(
                state,
                index,
                'startGuaranteed',
                startGuaranteed
            )
        },
        setInactive(state, { index, inactive }) {
            setStartMomentItemsProperty(
                state,
                index,
                'inactive',
                inactive
            )
        },
        setStandardPricingVisibility(
            state,
            { index, isStandardPricingVisible }
        ) {
            setStartMomentItemsProperty(
                state,
                index,
                'isStandardPricingVisible',
                isStandardPricingVisible
            )
            const invoiceItems = getArrayItems(
                state,
                'course.learningMethod.costs.details[0].invoiceItems'
            )
            /* create a new deep copy of invoice items inside cost details, without pointing to the 
                same reference object of the original array. So both arrays will not reflect the same
                changes to avoid shallow copying.
            */
            const clonedInvoiceItems = JSON.parse(JSON.stringify(invoiceItems))
            // copy invoice items of cost details when user click on copy from standard pricing button
            state.course.learningMethod.planning.startMoments[
                index
            ].invoiceItems = clonedInvoiceItems
        },
        addStartMomentItem(state) {
            const startMoments = getArrayItems(
                state,
                'course.learningMethod.planning.startMoments'
            )
            const emptyStartMomentItem = {
                externalId: '',
                type: 'Day',
                city: '',
                streetName: '',
                houseNumber: '',
                postcode: '',
                dayOfWeek: null,
                cutOffDate: null,
                startDate: {
                    fullDate: '', // combined startDate to show in recess date picker
                    day: '',
                    month: '',
                    year: ''
                },
                startGuaranteed: null,
                minParticipants: null, // this must be null to be accepted by our server
                maxParticipants: null, // this must be null to be accepted by our server
                modules: [{
                    name: 'Programma',
                    city: '',
                    dayOfWeek: null,
                    events: [{
                        eventDate: '',
                        startTime: '',
                        endTime: '',
                        city: '',
                        dayOfWeek: null,
                        building: '',
                        room: '',
                        isActive: true
                    }
                    ],
                    isActive: true
                }],
                isVisible: true,
                isActive: true, // to bind active attribute recess accordion to open newly added startmoment by default,
                hasErrors: false
            }

            addItem(startMoments, 'city', emptyStartMomentItem)
        },
        removeStartMomentItem(state, index) {
            const startMoments = getArrayItems(
                state,
                'course.learningMethod.planning.startMoments'
            )
            removeItem(startMoments, index, 0)
        },
        resetStartMomentsArray(state) {
            state.course.learningMethod.planning.startMoments = []
        },
        // Modules
        setModuleName(state, { startMomentIndex, moduleIndex, moduleName }) {
            setModuleItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                'name',
                moduleName
            )
        },
        setModuleCity(state, { startMomentIndex, moduleIndex, moduleCity }) {
            setModuleItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                'city',
                moduleCity
            )
        },
        setModuleDayOfWeek(
            state,
            { startMomentIndex, moduleIndex, dayOfWeek }
        ) {
            setModuleItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                'dayOfWeek',
                dayOfWeek
            )
        },
        addModuleItem(state, index) {
            const startMoment =
                state.course.learningMethod.planning.startMoments[`${index}`]
            const emptyModuleItem = {
                name: 'Programma',
                city: '',
                dayOfWeek: null,
                events: [
                    {
                        eventDate: '',
                        startTime: '',
                        endTime: '',
                        city: '',
                        dayOfWeek: null,
                        building: '',
                        room: '',
                        isActive: true
                    }
                ],
                isActive: true // to bind active attribute recess accordion to open newly added module by default
            }
            addItem(startMoment.modules, 'name', emptyModuleItem)
        },
        removeModuleItem(state, { startMomentIndex, moduleIndex }) {
            const modules =
                state.course.learningMethod.planning.startMoments[
                    `${startMomentIndex}`
                ].modules
            removeItem(modules, moduleIndex, 0)
        },
        // Events
        setEventDate(
            state,
            { startMomentIndex, moduleIndex, eventIndex, eventDate }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'eventDate',
                eventDate
            )
        },
        setEventStartTime(
            state,
            { startMomentIndex, moduleIndex, eventIndex, startTime }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'startTime',
                startTime
            )
        },
        setEventEndTime(
            state,
            { startMomentIndex, moduleIndex, eventIndex, endTime }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'endTime',
                endTime
            )
        },
        setEventDayOfWeek(
            state,
            { startMomentIndex, moduleIndex, eventIndex, dayOfWeek }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'dayOfWeek',
                dayOfWeek
            )
        },
        setEventCity(
            state,
            { startMomentIndex, moduleIndex, eventIndex, city }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'city',
                city
            )
        },
        setEventBuilding(
            state,
            { startMomentIndex, moduleIndex, eventIndex, building }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'building',
                building
            )
        },
        setEventRoom(
            state,
            { startMomentIndex, moduleIndex, eventIndex, room }
        ) {
            setEventItemsProperty(
                state,
                startMomentIndex,
                moduleIndex,
                eventIndex,
                'room',
                room
            )
        },
        addEventItem(state, { startMomentIndex, moduleIndex }) {
            const events =
                state.course.learningMethod.planning.startMoments[
                    `${startMomentIndex}`
                ].modules[`${moduleIndex}`].events
            const emptyEventItem = {
                eventDate: '',
                startTime: '',
                endTime: '',
                city: '',
                dayOfWeek: null,
                building: '',
                room: '',
                isActive: true // to bind active attribute recess accordion to open newly added module by default
            }
            addItem(events, 'eventDate', emptyEventItem)
        },


        duplicateEventItem(state, { startMomentIndex, moduleIndex, eventIndex }) {
            const events =
                state.course.learningMethod.planning.startMoments[
                    `${startMomentIndex}`
                ].modules[`${moduleIndex}`].events

            const duplicateEvent = JSON.parse(JSON.stringify(events[eventIndex]))
            addItem(events, 'eventDate', duplicateEvent)
        },

        removeEventItem(state, { startMomentIndex, moduleIndex, eventIndex }) {
            const events =
                state.course.learningMethod.planning.startMoments[
                    `${startMomentIndex}`
                ].modules[`${moduleIndex}`].events
            removeItem(events, eventIndex, 0)
        },

        duplicateStartmoment(state, startMomentIndex) {
            const startMoments = state.course.learningMethod.planning.startMoments
            const startMoment = startMoments[startMomentIndex]
            const duplicateStartMoment = JSON.parse(JSON.stringify(startMoment))
            
            delete duplicateStartMoment.id
            duplicateStartMoment.externalId = ''
            duplicateStartMoment.isActive = true

            addItem(startMoments, 'city', duplicateStartMoment)
        },


        // TODO: Remove when all course is using patch and not put        
        setSegments(state, { segments }) {
            state.course.segments = JSON.parse(JSON.stringify(segments))
        }
    }, 

    actions: {
        async fetchCourse({ commit }, id) {
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.courses,
                    id,
                    null,
                    false
                )
                if (!response) return
                /* * START MOMENTS * */
                // set isVisible to true for all future start moments that is greater than today in the response
                if (!response.learningMethod.planning) {
                    response.learningMethod.planning = {
                        startMoments: []
                    }
                }
                const startMoments = get(response,'learningMethod.planning.startMoments',null)

                formatFromUtcToLocalDate(response) 

                setIsVisibleInStartMoments(startMoments)
                

                // convert event datetime utc format to local time before commiting 'setCourse'
                formatEventDateTimeToLocal(startMoments)

                /* * INVOICE ITEMS * */
                // add vatPercentage to invoiceItem inside cost's details before setting the response data to course object in vuex
                const invoiceItems = getArrayItems(
                    response,
                    'learningMethod.costs.details[0].invoiceItems'
                )
                setCountryVatPercentageOptions(invoiceItems)

                // set isStandardPricingVisible to false to each start moment specific pricing to toggle copy standard pricing button
                setIsStandardPricingVisible(startMoments)

                // add vatPercentage to specificInvoiceItem inside startmoments before setting the response data to course object in vuex
                setVatPercentageOptionsForSpecificInvoiceItems(startMoments)

                /* * CONTENT ITEM * */
                const contentItem = get(response, 'content[0]', null)
                setDefaultSelectedTypesInContentItem(contentItem)

                /* * CATEGORIES * */

                addEmptyContentBlock(response)

                sortStartMoments(startMoments)

                // initialze properties if returned object is null
                initializeEmptyProperties(response)

                commit('setCourse', response)
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving the course details',
                    error
                )
            }
        },
        async postCourse({ dispatch, commit }, course) {
            // we make a copy and change the date before posting so the visual part to the user
            // doesn't change and in case of a erropr the start and end date are still ok
            const copyCourse = JSON.parse(JSON.stringify(course))

            delete copyCourse.source // delete source before posting as server doesnt allow to specific source in UI but we need this for BTW validation
            delete copyCourse.segments

            combineEventDateTime(copyCourse)

            updateInhoundBeforeSave(copyCourse)

            formatToUtcBeforeSave(copyCourse)

            updateEnumsBeforeSave(copyCourse)

            await postItem(
                process.env.VUE_APP_PROVIDER_API_URL,
                API_CALL_URL_PATHS.courses,
                copyCourse,
                false
            ).then((response) => {

                const toastNotification = {
                    type: 'success',
                    message: assortedProductSuccessAction('aangemaakt')
                }

                dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })

                addEmptyContentBlock(response)

                formatFromUtcToLocalDate(response)

                commit('setCourse', response)


            }).catch((error) => {

                const errorMessages = setCourseNotificationError(error)

                for (const errorMessage of errorMessages) {

                    if (errorMessage.itemIndex !== null) {
                        commit('setStartMomentErrors', { startMomentIndex: errorMessage.itemIndex, hasErrors: true })

                    }

                    const toastNotification = {
                        type: 'error',
                        message: errorMessage.message
                    }

                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                }
            })

        },
        patchCourse({
            dispatch
        }, {
            courseId,
            objectToBePatched,
            patchProperty
        }) {
            patchItem(
                process.env.VUE_APP_PROVIDER_API_URL,
                API_CALL_URL_PATHS.courses,
                courseId,
                objectToBePatched,
                false
            )
                .then((response) => {
                    if (!response) return

                    let toastNotification = {
                        type: 'success',
                        message: []
                    }

                    if (patchProperty) {
                        toastNotification.message = defaultEditSuccessMessage(patchProperty)
                    } else {
                        toastNotification.message = defaultEditSuccessMessage("Course")
                    }

                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })
                })
                .catch((error) => {
                    const errorMessages = setCourseNotificationError(error)

                    for (const errorMessage of errorMessages) {

                        const toastNotification = {
                            type: 'error',
                            message: errorMessage.message
                        }

                        dispatch('toastNotificationModule/add', toastNotification, {
                            root: true
                        })
                    }
                })

        },
        async updateCourse({ state, dispatch, commit }, course) {
            // we make a copy and change the date before posting so the visual part to the user
            // doesn't change and in case of a error the start and end date are still ok

            const copyCourse = JSON.parse(JSON.stringify(course))

            combineEventDateTime(copyCourse)

            updateEnumsBeforeSave(copyCourse)

            updateInhoundBeforeSave(copyCourse)

            // format accreditation dates, course startDate & endDate that is accepted by the server
            formatToUtcBeforeSave(copyCourse)

            // TODO: Remove when all course is using patch and not put
            // format subsegments to the format that is allowed by the server
            updateSubSegmentsBeforeSave(copyCourse)

            await putItem(
                process.env.VUE_APP_PROVIDER_API_URL,
                API_CALL_URL_PATHS.courses,
                copyCourse.id,
                copyCourse,
                false
            )
                .then(() => {
                    dispatch('fetchCourse', course.id)

                    const toastNotification = {
                        type: 'success',
                        message: assortedProductSuccessAction('bewerkt')
                    }

                    dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                })
                .catch((error) => {
                    const isOnEditCoursePage = true

                    const errorMessages = setCourseNotificationError(error, isOnEditCoursePage)

                    for (const errorMessage of errorMessages) {

                        if (errorMessage.itemIndex !== null) {
                            commit('setStartMomentErrors', { startMomentIndex: errorMessage.itemIndex, hasErrors: true })
                        }

                        const toastNotification = {
                            type: 'error',
                            message: typeof errorMessage === 'string' ? errorMessage : errorMessage.message
                        }

                        dispatch('toastNotificationModule/add', toastNotification, {
                            root: true
                        })
                    }
                })
        },
        initialize({ commit }) {
            // set course state with default values passed in the payload

            const newCourse =
            {
                externalId: '', // cannot be null so that vuesax's input component will not add 'hasValue' class to an empty field
                providerId: null,
                name: '', // cannot be null so that vuesax's input component will not add 'hasValue' class to an empty field
                commercialPartner: null,
                source: 'Manual',
                isProfessionalCourse: true,
                subsidies: null,
                learningMethod: {
                    type: null,
                    format: null,
                    costs: {
                        details: [getEmptyInvoiceItem()]
                    },
                    duration: {
                        unit: '',
                        value: null
                    },
                    planning: {
                        startMoments: []
                    },
                    studyLoad: {
                        value: null,
                        unit: null
                    },
                    language: '',
                    languageMaterial: ''
                },
                content: [
                    {
                        intro: null,
                        introHtml: null,
                        blocks: [
                            {
                                heading: 'Leerdoelen',
                                type: 'Objectives',
                                body: '',
                                bodyHtml: '',
                                selectedBody: 'text'
                            } 
                        ]
                    }
                ],
                productType: 'Regular',
                startDate: '',
                endDate: '',
                educationLevelCode: '',
                segments: [{
                    value: null,
                    displayText: null,
                    subSegments: [],
                    filteredSubSegmentOptions: []
                }]
            }
            commit('setCourse', newCourse)
        }
    }
}

export default courseModule
