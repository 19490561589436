import Vue from 'vue'
import VueBus from 'vue-bus'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import RecessUi from '@valtech/recess-ui'
import '@valtech/recess-ui/dist/main.min.css'
import 'material-icons/iconfont/material-icons.css'

import store from './store'
import router from './router'
import App from './App.vue'

// Vue filter
import currencyFilter from './utils/currencyFilter'
import dateFilter from './utils/dateFilter'

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
        loggingLevelConsole: process.env.VUE_APP_LOGGING_LEVEL_CONSOLE,
        loggingLevelTelemetry: process.env.VUE_APP_LOGGING_LEVEL_TELEMETRY
    }
})
appInsights.loadAppInsights()
appInsights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueBus)
Vue.use(RecessUi)

// Initialize gtag.js using vue-gtag package for Google Analytics (https://github.com/MatteoGabriele/vue-gtag)
Vue.use(
    VueGtag, {
    config: {
        id: 'UA-164967768-1',
        params: {
            anonymize_ip: true
        }
    }
},
    router
)

Vue.filter('currency', currencyFilter)
Vue.filter('formatDate', dateFilter)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
