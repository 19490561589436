<template>
	<recess-multi-select
		:selected-options="providerId"
		:options="getProviderSelectOptions"
		:search-input="true"
		:single-select="true"
		:disabled="isProviderSelectDisabled"
		:select-options-text="getProviderSelectOptions && getProviderSelectOptions[0].displayText"
		placeholder="Kies een leverancier"
		class="w-50 qa-provider-select-dropdown"
		@input="(newValue) => setNewSelectedProvider(newValue)"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
	name: 'ProviderSelectDropdown',
	data() {
		return {
			providerId: localStorage.getItem('providerId'),
			routeNamesWithDisabledDropdown: [
				'course-algemeen',
				'course-planning',
				'course-invoice',
				'course-content',
				'order-details',
				'course-categories',
				'course-diploma',
				'course-extra-options',
				'edudex-import-details'
			]
		}
	},
	computed: {
		...mapState('providerModule', ['selectedProvider']),
		...mapGetters('providerModule', ['getProviderSelectOptions']),
		isProviderSelectDisabled() {
			return this.routeNamesWithDisabledDropdown.includes(this.$route.name)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.getProviders() 
			if (this.selectedProvider && this.selectedProvider.id === null) {
				this.providerId = localStorage.getItem('providerId') 
				this.setNewSelectedProvider(this.providerId)
			} else {
				this.providerId = this.selectedProvider.id 
			}
		})
	},
	beforeDestroy() {
		this.resetProvidersState()
	},
	methods: {
		...mapMutations('providerModule', ['resetSelectedProviderState', 'resetProvidersState', 'resetProviderState', 'setSelectedProvider']),
		...mapActions('providerModule', ['fetchProviders', 'fetchProvider']),
		getProviders() {
			this.fetchProviders()
		},
		setNewSelectedProvider(selectedProvider) {
			if (!selectedProvider) {
				this.providerId = ''
				localStorage.removeItem('providerId')
				this.resetSelectedProviderState()
				this.resetProviderState()
			}
			
			else {
				this.fetchProvider(selectedProvider) 
				this.providerId = selectedProvider 
			}
		}
	}
}
</script>
