// Specific Messages
export const alphabetOnlyError = 'Alleen letters zijn toegestaan'
export const educationalFulfillmentDateInvalid = 'Datum moet later zijn dan het startmoment'
export const yearMinValueMessage = 'Het jaar moet groter of gelijk zijn aan 1900'
export const isValidBirthDateMessage = 'Leeftijd moet tussen 6 en 120 zijn'
export const clearAssortmentSuccess = 'Alle leervormen uit het assortiment zijn succesvol verwijderd'
export const patchPoNumberSuccess = 'Het PO nummer is succesvol bijgewerkt'
export const kycSuccessRequest = 'De KYC procedure is aangevraagd.'

export const priceBeforeTaxMinValue = 'Prijs moet groter of gelijk zijn dan 0'
export const vatPercentageMinMaxValue = 'BTW moet tussen 0 en 100 zijn'
export const invalidPercentageError = "Het percentage moet tussen 0 en 100 zijn, en maximaal 2 decimaal"
export const vatExemptAmountMinValue = 'Prijs BTW vrijgesteld moet groter of gelijk zijn dan 0'
export const vatExemptAmountVatMinValue = 'BTW bedrag BTW vrijgesteld moet groter of gelijk zijn dan 0'
export const isValidCityMessage = 'Regio/plaats is ongeldig'
export const minParticipantsMinValue = 'De minimale waarde kan niet groter zijn dan de maximale waarde'
export const maxParticipantsMinValue = 'De maximale waarde kan niet kleiner zijn dan de minimale waarde'
export const positiveDecimalNumber = 'Alleen positieve getallen met maximaal 1 decimaal zijn toegestaan'

// #region Toast Messages
//Success
export const specificInvoiceSettingsSuccessAction = (action) => {
    return `Assortiment specifieke facturatie is succesvol ${action}`
}

export const oppActionMessage = (isError = false) => {
    return isError ? `Nog niet geactiveerd voor betaling via Online Payment Platform` : `Geactiveerd voor betaling via Online Payment Platform`
}

export const defaultEditSuccessMessage = (propertyName) => {
    return `${propertyName} is succesvol bewerkt`
}

export const priceAgreementSuccessAction = (action) => {
  return `De kortingsafspraak is succesvol ${action}`
}

export const legalCompanySuccessAction = (action) => {
    return `De handelsnaam is succesvol ${action}`
}

export const assortmentSuccessAction = (action) => {
    return `Het assortiment is succesvol ${action}`
}

export const nameSuccessAction = (action) => {
    return `De naam is succesvol ${action}`
}

export const portfolioSuccessAction = (action) => {
    return `De portfolio is succesvol ${action}`
}

export const portfolioImportSuccessAction = (action) => {
    return `Het geselecteerde leeraanbod is succesvol ${action}`
}

export const providerSuccessAction = (action, isPlural = false) => {
    return isPlural ? `De leveranciers zijn succesvol ${action}` : `De leverancier is succesvol ${action}`
}

export const assortedProductSuccessAction = (action, isPlural = false) => {
    return isPlural ? `De opleiding(en) is/zijn succesvol ${action}` : `Het leeraanbod is succesvol ${action}`
}

export const contractSuccessAction = (action) => {
    return `Het contract is succesvol ${action}`
}

export const courseSuccessAction = (action) => {
    return `De opleiding is succesvol ${action}`
}

export const tagSuccessAction = (action) => {
    return `De tag is succesvol ${action}`
}

export const coursePropertySuccessAction = (action) => {
    return `Aanbod is succesvol ${action}`
}

export const addProviderToAssortmentSources = (action) => {
    return `Aanbod leverancier succesvol ${action}`
}

export const accountSuccessAction = (action, isPlural = false) => {
    return isPlural ? `De accounts zijn succesvol ${action}` : `Het account is succesvol ${action}`
}

export const importSuccessAction = (action) => {
    return `De import is successvol ${action}`
}

export const feeSuccessAction = (action) => {
    return `De vergoeding is succesvol ${action}`
}

export const costTypeSuccessAction = (action) => {
    return `De korting is succesvol ${action}`
}

export const invoiceSettingsSuccessAction = (action) => {
    return `De facturatie instelling is succesvol ${action}`
}

export const emailSuccessAction = (action, isPlural = false) => {
    return isPlural ? `De e-mailadres(sen) zijn succesvol ${action}` : `Het e-mailadres is succesvol ${action}`
}

export const studentSuccessAction = (action) => {
    return `Student is succesvol ${action}`
}

export const orderSuccessAction = (action, isPlural = false) => {
    return isPlural ? `Alle orders zijn succesvol ${action}` : `De order is succesvol ${action}`
}

export const orderSatusSuccessAction = (action) => {
    return `Order status is succesvol ${action}`
}

export const educationLevelSuccessAction = (action, isPlural = false) => {
    return isPlural ? `Het leeraanboden zijn succesvol ${action}` : `Het leeraanbod is succesvol ${action}`
}

export const oleUserSuccessAction = (action) => {
    return `Het LMS is succesvol ${action}`
}

//Error

export const removeSegmentError = 'Dit segment kan niet verwijderd worden, er moet minstens één segment zijn'
export const mandatoryFieldsError = 'Alle verplichte velden moeten eerst gevuld zijn'
export const mandatoryFieldsOnDuplicateStartmomentError = 'Dit startmoment kan niet worden gedupliceerd omdat niet alle verplichte velden correct zijn ingevuld'
export const submitSegmentOrSubsegmentError = 'Sla eerst de pagina op voordat je een subsegment aan dit segment kunt toevoegen'
export const addContractDefaultError = 'Er is een fout opgetreden bij het aanmaken van de contract'
export const uniqueEntityContractError = 'Er is al een contract aanwezig voor deze leverancier'
export const corruptedPdfError = 'Het meegeleverde PDF-bestand is beschadigd'
export const accountNameAlreadyExistsError = 'Deze naam is al in gebruik voor een (verwijderd) account'
export const accountPostError = 'Er is een fout opgetreden bij het aanmaken van het account'
export const btwMaxCharactersError = 'BTW nummer mag niet langer zijn dan 50 karakters'
export const legalCompanyMissingVatNumberError = 'Deze handelsnaam heeft nog geen BTW nummer, kies alsjeblieft een andere handelsnaam'
export const selectAccountError = 'Selecteer eerst een account'
export const invalidCsvFormatError = 'Het CSV bestand is ongeldig. Probeer het opnieuw met het formaat CSV UTF-8'
export const legalCompanyUniqueEmailError = 'Het ingevoerde e-mailadres is al in gebruik voor een andere handelsnaam'
export const legalCompanyRequiredEmailError = 'Een e-mailadres is vereist om de KYC procedure te starten'
export const invalidEdudexDirectoryError = 'De structuur van de XML komt niet overeen met de Edudex standaard'
export const noProgramResourceError = 'Er zijn geen opleidingen aanwezig in de opgegeven url'
export const invalidXmlFileError = 'De opgegeven url is geen geldig XML bestand'
export const uniqueGroupDiscountError = 'Er is al een kortingsafspraak aanwezig voor de ingevoerde gegevens'
export const providerNotFoundError = '1 of meerdere producten is van een verwijderde leverancier'
export const providerDeletedError = 'Deze opleider is verwijderd'
export const requiredPurchaseOrderError = 'Er is een PO nummer verplicht voor deze order'
export const statusChangeNotSupportedError = 'De gevraagde statuswijziging is niet toegestaan.'
export const postInvoiceSettingsError = 'Er is een fout opgetreden bij het aanmaken van Assortiment specifieke facturatie'
export const patchInvoiceSettingsError = 'Er is een fout opgetreden bij het bijwerken van de facturatie instelling'
export const deleteInvoiceSettingsError = 'Assortiment specifieke facturatie is verwijderd'
export const addInvoiceSettingsError = 'Je dient alle velden te vullen voor je nog een regel kunt toevoegen'
export const addNewContentBlockError = 'Open tekstblok(ken) moeten eerst opgeslagen worden'
export const missingMandatoryFieldsError = 'Er ontbreken verplichte velden'
export const assormentDeleteError = 'Er is iets misgegaan bij het verwijderen van het assortiment. Probeer het nog een keer'
export const legalAgreementError = 'Je moet het cookie statement accepteren om EduMS te gebruiken'
export const inactiveCourseNotAddedError = 'Opleiding niet toegevoegd - de opleiding is nog niet actief'
export const missingCourseError = 'Selecteer leeraanbod voor dit project'
export const defaultErrorMessage = 'Er is iets mis gegaan, probeer het opnieuw of neem contact met ons op via het Support Center'
export const patchOrderErrorMessage = 'Cursus prijs komt niet overeen met afgesproken weergave prijs per deelnemer'

export const defaultCreateErrorMessage = (propertyName) => {
    return `Er is een fout opgetreden bij het aanmaken van ${propertyName}.` 
}

export const defaultEditErrorMessage = (propertyName) => {
    return `Er is een fout opgetreden bij het bijwerken van ${propertyName}.`
}

export const defaultDeleteError = (propertyName) => {
    return `${propertyName} is niet verwijderd`
}

export const atLeastOneSelectedError = (item) => {
    return `Oeps, er moet minstens één ${item} zijn`
}

export const minimumSelectionExportError = (item) => {
    return `Selecteer minstens 1 ${item} om te exporteren`
}

export const legalCompanyMissingCreditNumberError = (lang = 'nl') =>  lang === 'nl' ? 'De order kan niet worden goedgekeurd omdat er nog geen crediteurnummer is opgevoerd. Graag contact opnemen met de servicedesk' : 'The requested status change is not allowed because the chosen legal company has no credit number'

export const accountErrorAction = (action) => {
   return `Er is een fout opgetreden bij het ${action} van het account`
}

export const providerErrorAction = (action) => {
    return `Er is een fout opgetreden tijdens het ${action} van de provider`
}

export const feeErrorAction = (action) => {
    return `Er is iets fout gegaan tijdens het ${action} van de vergoeding`
}

export const costTypeErrorAction = (action) => {
    retun `Er is iets fout gegaan tijdens het ${action} van de korting`
}

export const addToAssortmentErrorAction = (action) => {
    return `Aanbod kon niet ${action} worden`
}

export const articleErrorAction = (action) => {
    return `Artikel niet succesvol ${action}`
}

export const videoErrorAction = (action, isPlural = false) => {
    return isPlural ? `Video's niet succesvol ${action}` : `Video niet succesvol ${action}`
}

export const systemInterruptionErrorAction = (action) => {
    return `Storingsbericht ${action} voor alle gebruikers`
}

// #endregion

//#region Field Errors
export const requiredError = (propertyName, isPlural = false) => {
    return isPlural ? `${propertyName} zijn verplicht` : `${propertyName} is verplicht`
}

export const invalidError = (propertyName) => {
    return `${propertyName} is ongeldig`
}

export const notValidError = (propertyName) => {
   return `${propertyName} is niet geldig` 
}

export const startOrEndDateErrorMessage = (date, property) => {
    if (date === 'minDate') {
        return `De ${property.startDate} moet kleiner of gelijk zijn dan de ${property.endDate}`
    } 

    return `De ${property.endDate} moet groter of gelijk zijn dan de ${property.startDate}`
}

export const isDateInThePast = (validationProperty, rule) => {
    return validationProperty && !validationProperty[rule] ?
        'Datum moet in de toekomst zijn' :
        null 
}

export const hasEmailError = validationProperty => {
    return validationProperty && !validationProperty.email ?
    'E-mailadres is niet geldig' :
    null
}

export const uniqueError = propertyName => {
    return `${propertyName} moet uniek zijn`
}

export const minMaxLengthErrorMessage = (propertyName, minLength, maxLength) => {
    return `${propertyName} moet tussen ${minLength} en ${maxLength} letters zijn`
}

export const defaultNotFoundErrorMessage = propertyName => {
    return `Het ${propertyName} bestaat niet`
}

export const integerError  = propertyName => {
    return `${propertyName} moet positief zijn en een geheel getal`
}

export const negativeNumber = (propertyName) => {
    return `${propertyName} moet groter zijn dan 0`
}
//#endregion

//#region Message Functions
export const hasError = (errorMessage, validationProperty, rule) => {
    return validationProperty && validationProperty.$error && !validationProperty[rule] ?
        errorMessage :
        null
}

export const isRequiredErrorMessage = (property, propertyDisplayText, validationProperty, childObj, plural = false) => {
    const validationObj = childObj ? validationProperty[childObj][property] : validationProperty
    return validationObj && validationObj.$error && !validationObj.required ?
        requiredError(propertyDisplayText, plural) :
        null
}

export const dateRangeHasErrorMessage = (errorMessageType, validationObj, rule, property = {startDate: 'van datum', endDate: 'tot datum' }) => {
    return validationObj && validationObj.$invalid && !validationObj[rule] ? startOrEndDateErrorMessage(errorMessageType, property) : null
}

export const isInvalidErrorMessage = (propertyDisplayText, validationProperty, rule) => {
    return validationProperty && validationProperty.$invalid && !validationProperty[rule] ?
        invalidError(propertyDisplayText) :
        null
}

export const isUniqueErrorMessage = (propertyDisplayText, validationProperty, rule) => {
    return validationProperty && !validationProperty[rule] ?
        uniqueError(propertyDisplayText) :
        null
}

export const isValidIntegerErrorMessage = (propertyDisplayText, validationProperty, rule) => {
    return validationProperty && !validationProperty[rule] ?
        integerError(propertyDisplayText) :
        null
}

//#endregion