import { get, set, last } from 'lodash'
import { ERROR_MESSAGES } from '@/../../shared/constants/constantsGeneral.json' 
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { toISODateString, formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'
import { sortByDate } from '@/utils/validationHelpers' 
import { requiredError, defaultEditErrorMessage  } from '@/../../shared/constants/validationMessageHelper.js'



/*  Generic reusable functions for ALL modules */
/* Set property on state
   setUser: set('user')
   commit('setUser', { name: 'foo' }) */
export const setProperty = (key) => (state, val) => {
    state[key] = val
}

/* Set a value at a path within state
   Create objects and arrays as needed
   Path is an array, and array indicies are numbers (not string numbers)
   setUserName: setPath(['user', 'name'])
   commit('setUserName', 'foo') */
export const setPath = (path) => (state, val) => {
    const obj = path.slice(0, -1).reduce((acc, x, i) => {
        if (!(x in acc)) acc[x] = typeof path[i + 1] === 'number' ? [] : {}
        return acc[x]
    }, state)
    obj[last(path)] = val
}

// get an array from state with specific attributes
export const getArrayItems = (state, attributes) => {
    const arrayItems = get(state, attributes, null)
    return arrayItems
}

// add item from list
export const addItem = (items, key, emptyRow) => {
    // only allow to add more than 1 row if user filled in at least one value
    const checkEmptyItems = items.filter((item) => item[key] === null || item[key] === '')
    // allow duplicating items which are not invalid but have invalid siblings
    if (checkEmptyItems.length > 0 && (emptyRow[key] === null || emptyRow[key] === '')) return
    items.push(emptyRow)
}

// remove item from list
export const removeItem = (items, indexToRemove, defaultIndex) => {
    if (items && items.length > defaultIndex) {
        items.splice(indexToRemove, 1)
    }
}

/*  Reusable functions for course module only */
// Find and set value in array of nested objects within state
export const setContentBlockProperty = (state, contentBlockIndex, key, val, displayValue) => {
    // get an array from state with specific attributes
    const contentItem = get(state, 'course.content[0]', null)
    
    // set property value from payload to the targeted property in the array items by specific index from payload
    if(displayValue !== undefined && contentItem.blocks[contentBlockIndex].typeDisplayValue !== displayValue) {
        set(contentItem.blocks[contentBlockIndex], 'displayValue', displayValue)
        set(contentItem.blocks[contentBlockIndex], 'typeDisplayValue', displayValue)
    }
    set(contentItem.blocks[contentBlockIndex], key, val)
}

// Find and set value in array of nested objects within state
export const setInvoiceItemsProperty = (state, startMomentIndex, index, key, val) => {

    const path = startMomentIndex === null
        ? 'course.learningMethod.costs.details[0].invoiceItems'
        : `course.learningMethod.planning.startMoments[${startMomentIndex}].invoiceItems`
    const invoiceItems = get(state, path, null)

    set(invoiceItems[index], key, val)
}

// Find and set value in array of nested objects within state
export const setStartMomentItemsProperty = (state, index, key, val) => {
    const startMoments = get(state, 'course.learningMethod.planning.startMoments', null)
    set(startMoments[index], key, val)
}

// Find and set value in array of nested objects within state
export const setModuleItemsProperty = (state, startMomentIndex, moduleIndex, key, val) => {
    const startMoments = get(state, 'course.learningMethod.planning.startMoments', null)
    set(startMoments[startMomentIndex].modules[moduleIndex], key, val)
}

// Find and set value in array of nested objects within state
export const setEventItemsProperty = (
    state,
    startMomentIndex,
    moduleIndex,
    eventIndex,
    key,
    val
) => {
    const startMoments = get(state, 'course.learningMethod.planning.startMoments', null)
    set(startMoments[startMomentIndex].modules[moduleIndex].events[eventIndex], key, val)
}

export const addEmptyContentBlock = response => {  
    const emptyContentItem = {
        blocks: [],
        description: '',
        descriptionHtml: '',
        intro: '',
        introHtml: ''
    }

    if (response.content && response.content.length === 0) {
        response.content.push(emptyContentItem)
    }

    if (response.content[0].blocks && response.content[0].blocks.length < 1) {
        response.content[0].blocks = []
    }
}

export const setCourseNotificationError = (error, isOnEditCoursePage) => {  
    
    const errorMessages = [] 

    if (error.response && error.response.data) { 

        if (error.response.data.error.message ) {
                
            // unique course external id validation
            if (error.response.data.error.message.indexOf('The combination of ExternalId and ProviderId should be unique.') !== -1) {
                errorMessages.push({message: ERROR_MESSAGES.uniqueCourseIdAndProviderId, itemIndex: null})
            }            
            
            // Subsidies
            if (error.response.data.error.message.indexOf('Property Subsidies[0].Value failed validation. Error was: Subsidies has an unknown subsidy.') !== -1) {
                errorMessages.push({message: defaultEditErrorMessage('Subsidies'), itemIndex: null})
            }

            if (error.response.data.error.message.indexOf('Please try again later') !== -1) {
                errorMessages.push({message: defaultEditErrorMessage('opleiding'), itemIndex: null})
            } 
            
        }
         

        if(error.response.data.error && error.response.data.error.modelState) {
            let retrieveModelState = {}
            retrieveModelState = JSON.parse(JSON.stringify(error.response.data.error.modelState)) 
 

            for (const modelStateMessage in retrieveModelState) {  

                const regexGetStartMomentIndex = /\[([^)])\]/;
                const getStartMomentIndex = regexGetStartMomentIndex.exec(modelStateMessage)  
                const startMomentIndex = getStartMomentIndex ? getStartMomentIndex[1] : null

                const splitModelStateProperties = modelStateMessage.split('.')
                const propertyName = splitModelStateProperties[splitModelStateProperties.length - 1]


                // General  
                if(propertyName === 'Name' && splitModelStateProperties.length === 1) {
                    errorMessages.push({message: ERROR_MESSAGES.nameIsEmpty, itemIndex: null})
                }

                if(propertyName === 'EndDate' && splitModelStateProperties.length === 1) {
                    errorMessages.push({message: ERROR_MESSAGES.EndDate, itemIndex: null})
                } 

                if(propertyName === 'ExternalId' && splitModelStateProperties.length === 1) { 
                    errorMessages.push({message: ERROR_MESSAGES.externalIdIsEmpty, itemIndex: null})
                } else if(modelStateMessage === 'ExternalId.Length') {
                    errorMessages.push({message: "ExternalId moet kleiner of gelijk zijn aan 36 tekens", itemIndex: null})
                }
 

                if(propertyName === 'EducationLevelCode' && splitModelStateProperties.length === 1) { 
                    const errorMessage = requiredError('Opleidingsniveau')
                    errorMessages.push({message: errorMessage, itemIndex: null})
                }  

 
                // StartMoments 
                if(modelStateMessage.indexOf('StartMoments') !== -1) {  
                    if(propertyName === 'City') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.EmptyCityName, itemIndex: startMomentIndex})
                    }

                    if(propertyName === 'ExternalId') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.UniqueStartMomentId, itemIndex: startMomentIndex})
                    } 

                    if(propertyName === 'Year' && isOnEditCoursePage) { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.InvalidStartmomentDate, itemIndex: startMomentIndex})
                    }

                    if(propertyName === 'MaxParticipants') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.MinStudents, itemIndex: startMomentIndex})
                    }

                    if(propertyName === 'EventDate') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.InvalidEventDate, itemIndex: startMomentIndex})
                    }

                    if(propertyName === 'Name') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.EmptyModuleName, itemIndex: startMomentIndex})
                    }

                    if(propertyName === 'CutOffDate') { 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.Startmoments.Messages.Error.CutOffDate, itemIndex: startMomentIndex})
                    }
                } 

                // InvoiceItems 
                if(modelStateMessage.indexOf('InvoiceItems') !== -1) { 
                    if (propertyName === 'CostType'){ 
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Messages.Error.EmptyCostType, itemIndex: null})
                    } 
                    if (propertyName === 'PriceBeforeTax'){
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Messages.Error.EmptyPriceBeforeTax, itemIndex: null})
                    } 
                    if (propertyName === 'VatAmount'){  
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Messages.Error.EmptyVatAmount, itemIndex: null})
                    } 
                    if (propertyName === 'VatExemptAmount'){  
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Messages.Error.EmptyVatExemptAmount, itemIndex: null})
                    } 
                    if (propertyName === 'VatExemptAmountVAT'){
                        errorMessages.push({message: PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.Messages.Error.EmptyVatExemptAmountVAT, itemIndex: null})
                    } 
                } 

                // Learning Method Duration
                if(modelStateMessage.indexOf('LearningMethod.Duration') !== -1) {  
                    if(propertyName === 'Unit') { 
                        const errorMessage = requiredError('Doorlooptijd')
                        errorMessages.push({message: errorMessage, itemIndex: null})
                    } 
    
                    if(propertyName === 'Value') { 
                        const errorMessage = requiredError('Doorlooptijd eenheid')
                        errorMessages.push({message: errorMessage, itemIndex: null})
                    } 
                }

                if(modelStateMessage.indexOf('LearningMethod') !== -1) {  
                    if(propertyName === 'Type' || propertyName === 'Format') { 
                        const errorMessage = requiredError('Lesmethode')
                        errorMessages.push({message: errorMessage, itemIndex: null})
                    } 
                }
            }
        }
    } 

    if(error.response && error.response.data && error.response.data.error.message && error.response.data.error.message === 'The segments have duplicate values.') { 
        errorMessages.push(ERROR_MESSAGES.uniqueSegment)
    }
    if(error.response && error.response.data && errorMessages.length === 0) { 
        errorMessages.push(ERROR_MESSAGES.requiredFieldsAreMissing)
    }

    return errorMessages
}

export function setIsVisibleInStartMoments(startMoments, showActive = true) {
    const today = new Date().setHours(0,0,0,0)
    if (startMoments) {
        startMoments.forEach((startMoment) => {
            const isoDate = toISODateString(startMoment.startDate)
            let startDate = ''

            if(isoDate) {
                startDate = new Date(isoDate)
            } else {
                startDate = new Date(startMoment.startDate)
            }
            
            if (startDate >= today && !startMoment.inactive) {
                startMoment.isVisible = showActive
            } else {
                startMoment.isVisible = !showActive
                startMoment.inactive = true
            }

            startMoment.hasErrors = false
        })
    }
    return null
}

export function sortStartMoments(startMoments) {
    if(startMoments) {
        // sort start moments by the most recent date before commiting 'setCourse'
        return startMoments.sort((firstDate, lastDate) => 
             sortByDate(firstDate.startDate, lastDate.startDate))
    } 
    return null
}

export function formatStartMomentsBeforeSave(startmoment) {
    const isoDate = toISODateString(startmoment.startDate)
    const dateFormat = new Date(isoDate)

    startmoment.startDate = {
        day: (dateFormat.getDate()).toString().padStart(2, '0'),
        month: (dateFormat.getMonth() + 1).toString().padStart(2, '0'),
        year: (dateFormat.getFullYear()).toString()
    }
}

export function setCountryVatPercentageOptions(invoiceItems) {
    if (invoiceItems) {
        invoiceItems.forEach((invoiceItem) => {

            invoiceItem.vatAmount = (invoiceItem.priceBeforeTax * invoiceItem.vatPercentage / 100).toFixed(2)

            // add selectedCountryVatPercentages in each invoice item when getting data back from server
            invoiceItem.vatPercentageOptions = []
        })
    }
    return null
}

export function setVatPercentageOptionsForSpecificInvoiceItems(startMoments) {
    if (startMoments) {
        startMoments.forEach((startMoment) => {
            const specificInvoiceItems = startMoment.invoiceItems
            setCountryVatPercentageOptions(specificInvoiceItems)
        })
    }
}

export function setIsStandardPricingVisible(startMoments) {
    if (startMoments) {
        startMoments.forEach((startMoment) => {
            startMoment.isStandardPricingVisible = false
        })
    }
}

export function setDefaultSelectedTypesInContentItem(contentItem) {
    if (contentItem) {
        contentItem.selectedDescription = 'text'
        contentItem.selectedIntro = 'text'
        contentItem.blocks.forEach((contentBlock) => {
            contentBlock.selectedBody = 'text'
        })
    }
    return null
}

export function setDataToBePatchedWhenValueIsUpdated(originalArray, updatedObj, key, dataToBePatched) {
    // check if property value is updated by comparing wtih original array
    // if yes, we set data objects to be patched
    originalArray.map((originalObj) => {
        const originalValue = key.includes('Date')
            ? formatUtcDateTimeToLocalDate(originalObj[key])
            : originalObj[key]

        if (originalObj.id === updatedObj.id && originalValue !== updatedObj[key]) {
            dataToBePatched.push({
                value: key.includes('Date')
                    ? toISODateString(updatedObj[key])
                    : updatedObj[key],
                path: key,
                op: 'replace'
            })
        }
        return null
    })
}

export function updateMultiSelectList(property, isInitialized) {
    let mappedProperty
    if (Array.isArray(property) && property.length > 0) {
        mappedProperty = property.map((item) => {
            return !isInitialized
                ? {
                    value: item
                }
                : item.value
        })
    }
    return mappedProperty
}

export function updateEnumsBeforeSave(course) {
    course.competences = updateMultiSelectList(course.competences)

    course.competencesRijksoverheid = updateMultiSelectList(course.competencesRijksoverheid)

    course.targetSector = updateMultiSelectList(course.targetSector)

    course.targetSectorRijksoverheid = updateMultiSelectList(course.targetSectorRijksoverheid)

    course.subsidies = updateMultiSelectList(course.subsidies)

    updateEnums(course)

    return course
}

function updateEnums(course) {
    if(!course)
    {
        return
    }
    if(course.diplomaType === '')
    {
        course.diplomaType = null
    }
    if(course.credit && course.credit.type === '')
    {
        course.credit.type = null
    }
    if(course.accreditation && course.accreditation.type === '')
    {
        course.accreditation.type = null
    }
}

export function updateInhoundBeforeSave(course) {
    if (course && course.content[0] && !course.content[0].blocks)
        return

    course.content[0].blocks = course.content[0].blocks.filter((blockItem) => {
        return blockItem.bodyHtml !== "" || blockItem.body !== "" || blockItem.type !== "" || blockItem.heading !== ""
    })
}

const getEmptyAccreditation = () => {
    return {
        points: null,
        type: null,
        validFrom: null,
        validTo: null
    }
}

const getEmptyCredit = () => {
    return {
        type: null,
        value: null
    }
}

const getEmptyStudyLoad = () => {
    return {
        value: null,
        unit: null
    }
}

const getEmptyRequirements = () => {
    return {
        type: null,
        typeDisplayValue: null,
        description: []
    }
}

export function initializeExtraOptionProperties(course) {
    course.competences = updateMultiSelectList(course.competences, true)
    course.subsidies = updateMultiSelectList(course.subsidies, true)

    if (course.competencesRijksoverheid) {
        course.competencesRijksoverheid = course.competencesRijksoverheid.filter(
            (competencesRijksoverheidItem) =>
                competencesRijksoverheidItem.displayValue !== 'Onbekend'
        )
        course.competencesRijksoverheid = updateMultiSelectList(
            course.competencesRijksoverheid,
            true
        )
    }
    course.targetSector = updateMultiSelectList(course.targetSector, true)
    course.targetSectorRijksoverheid = updateMultiSelectList(course.targetSectorRijksoverheid, true)
}

export function initializeEmptyProperties(course) {
    if (!course.accreditation) {
        course.accreditation = getEmptyAccreditation()
    }
    if (!course.credit) {
        course.credit = getEmptyCredit()
    }
    if (course.learningMethod && !course.learningMethod.studyLoad) {
        course.learningMethod.studyLoad = getEmptyStudyLoad()
    }
    if (!course.requirements) {
        course.requirements = getEmptyRequirements()
    }
    initializeExtraOptionProperties(course)
    return null
}

export function getEmptyInvoiceItem() {
    return {
        invoiceItems: [{
            quantity: 1, // always 1
            priceBeforeTax: null,
            vatAmount: 0,
            vatCountry: 'NL',
            vatPercentage: null, // this property is used in the front end to calculate vatAmount
            vatExemptAmount: 0,
            vatExemptAmountVAT: 0,
            costType: null,
            vatPercentageOptions: []
        }]
    }
}

export function getEmptyStartMomentItem() {
    return {
        externalId: '',
        type: null,
        city: '',
        dayOfWeek: null,
        startDate: {
            fullDate: '', // combined startDate to show in recess date picker
            day: '',
            month: '',
            year: ''
        },
        minParticipants: null,
        maxParticipants: null,
        modules: [],
        hasErrors: false
    }
}

// TODO: Remove when all course is using patch and not put  
export function updateSubSegmentsBeforeSave(course) {
    if (Array.isArray(course.segments) && course.segments.length > 0) {
        course.segments = course.segments.reduce((result, segment) => {
            if (segment.value) {
                segment.subSegments = segment.subSegments.map((subSegment) => {
                    if (subSegment.value) {
                        return {
                            value: subSegment.value
                        }
                    }

                    return {
                        value: subSegment
                    }

                })
                result.push(segment)
            }
            return result
        }, [])
    }
    return course
}
