<template functional>
    <div class="c-loader">
        <img alt="spinner loader" class="c-loader__image" src="@/assets/images/loader.gif" />
    </div>
</template>

<script>
export default {
    name: 'SpinnerLoader'
}
</script>
