<template>
    <div :class="['c-notification-panel', {'is-active': isVisible}]" :style="`top: calc(${setTop}px); height: calc(100vh - ${setTop}px)`">
        <div class="c-notification-overlay" @click="close"></div>
        <div class="c-notification-holder">
            <h3 class="mb-4"> {{ PROVIDER.NotificationPanel.Title }} </h3>
             
            
            <div v-if="hasNotifications">
                <div 
                    class="c-notification-item"
                    @click="goToNotificationPage"
                >
                    <recess-notification-item class="w-100">
                        <span>{{ PROVIDER.NotificationPanel.GenericNotification }}</span>
                    </recess-notification-item> 
                </div> 
            </div> 

            <div v-else> 
                <p>{{ PROVIDER.NotificationPanel.HasNoNotifications }}</p>
            </div>
        </div>
    </div>
</template> 

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json' 

export default {
    name: 'NotificationPanel', 
    data() {
        return {
            PROVIDER,
            setBadge: false,
            isVisible: false,
            hasNotifications: true 
        }
    },
    computed: {
        headerHeight() {
            if(document.getElementsByClassName('c-header')[0]) {
                return document.getElementsByClassName('c-header')[0].clientHeight 
            }
            return null
        },
        navigationHeight() {
            if(document.getElementsByClassName('c-navigation')[0]) {
                return document.getElementsByClassName('c-navigation')[0].clientHeight 
            }
            return null
        },
        setTop() {
            return this.navigationHeight + this.headerHeight
        }
    },
    watch: {
        $route: {
            handler() {
                this.close()

                if(this.$route.name === 'whats-new') {
                    localStorage.setItem('provider_notificationRead', true) 

                    this.manageNotificationStatus() 
                }
            }
        } 
    },
    async mounted() {
        this.$bus.on('show-new-features', this.open)

        this.manageNotificationStatus() 
    },
    beforeDestroy() {
        this.$bus.off('show-new-features')
    },
    methods: { 
        open() {    
            this.isVisible = !this.isVisible  
        },
        close() {
            this.isVisible = false
            const htmlBody = document.getElementsByTagName('html')[0]
            htmlBody.classList.remove('u-overflow-hidden') 
        },
        manageNotificationStatus() {
            const checkNotificationStatus = localStorage.getItem('provider_notificationRead')  
            
            if(checkNotificationStatus) {  
                this.hasNotifications = false 
                this.$bus.emit("has-notification-badge", false)
            }
        },
        goToNotificationPage() { 
            localStorage.setItem('provider_notificationRead', true) 

            this.manageNotificationStatus() 

            if(this.$route.name !== 'whats-new') {
                this.$router.push({ name: 'whats-new' }) 
            }
        }
    }
}
</script>
 
