import axios from 'axios'

const CancelToken = axios.CancelToken
let cancel

export function getProviders(page, rowsPerPage, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.VUE_APP_PROVIDER_API_URL}providers?offset=${page}&limit=${rowsPerPage}&filter=deleted%20eq%20false`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}
export function getProviderById(id, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    if (!id) {
        throw new TypeError('Invalid parameters (id)')
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.VUE_APP_PROVIDER_API_URL}providers/${id}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}
export function patchProviderImportUrl(provider, cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.VUE_APP_PROVIDER_API_URL}providers/${provider.id}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
            data: [{
                value: provider.importUrl,
                path: 'importUrl',
                op: 'replace'
            }]
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}
export default {
    getProviders,
    getProviderById,
    patchProviderImportUrl
}