<template>
    <div class="c-impersonate">
        <div class="o-layout">
            <div class="o-layout__inner--dashboard">
                <div class="o-layout__wrapper">
                    <div
                        class="
                            c-impersonate__wrapper
                            d-flex
                            justify-content-between
                            align-items-center
                        "
                    >
                        <p class="m-0 u-fw-semi-bold align-self-center">
                            {{ PROVIDER.ImpersonateBar.Title }}: {{ impersonatedUsername }}
                        </p>

                        <recess-button
                            variant="primary"
                            :title="PROVIDER.ImpersonateBar.BackButton"
                            class="qa-log-out-from-impersonating-button"
                            @click.native.prevent="signOutAndRedirectToEduMSCore()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { postDeimpersonateUser } from '@/api/ImpersonateClient'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'ImpersonateBarComponent',
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            impersonatedUsername: null
        }
    },
    async created() {
        this.setImpersonatedUserData()
    },
    mounted() {
        this.setPadding()
    },
    methods: {
        async setImpersonatedUserData() {
            let user = await this.userService.getUser()

            this.impersonatedUsername = user.profile.name
        },
        signOutAndRedirectToEduMSCore() {
            postDeimpersonateUser(true)
                .then((response) => {
                    if (response.status === 200) {
                        this.userService.signOutDeImpersonate()
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        setPadding() {
            const impersonateHeight =
                document.getElementsByClassName('c-impersonate')[0].offsetHeight

            const mainApp = document.getElementById('app')
            const mainAppStyle = mainApp.style

            mainAppStyle.setProperty('padding-top', `${impersonateHeight}px`)
        }
    }
}
</script>
