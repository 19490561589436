import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { roleAccess as userAccessRoles, roleAccessProviderUI, roleAccessCoreUI } from '../constants/constantsGeneral.json'

var oidcUserManager = new UserManager({
    response_mode: 'query',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: process.env.VUE_APP_AUTHORITY_URL,
    client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
    response_type: 'code',
    scope: 'role openid profile',
    extraQueryParams: {
        source: process.env.VUE_APP_OIDC_CLIENT_ID
    },
    redirect_uri: `${window.location.origin}/login.html`,
    silent_redirect_uri: `${window.location.origin}/renew.html`,
    post_logout_redirect_uri: `${window.location.origin}`,
    accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: true,
    filterProtocolClaims: false,
    loadUserInfo: true
})

if (process.env.NODE_ENV === 'development') {
    Log.logger = console
    Log.level = Log.INFO
}

oidcUserManager.events.addAccessTokenExpiring(function () {
});

oidcUserManager.events.addAccessTokenExpired(function () {
    console.warn('Session expired. Logging out!');
    localStorage.removeItem('providerId')
    localStorage.removeItem('provider_previous_page')
    oidcUserManager.signoutRedirect().catch(function (error) {
        console.error(error)
    })
});

oidcUserManager.events.addSilentRenewError(function () {
    console.error('Silent renew error：', arguments);
});

oidcUserManager.events.addUserSignedOut(function () {
    localStorage.removeItem('providerId')
    localStorage.removeItem('provider_previous_page')
    oidcUserManager.signoutRedirect().catch(function (error) {
        console.error(error)
    })
});

export default class UserService {
    // Redirects user to the login page
    signIn() {
        oidcUserManager.signinRedirect().catch((error) => {
            console.error(error)
        })
    }

    signInCallback() {
        oidcUserManager.signinRedirectCallback().catch((error) => {
            console.error(error)
        })
    }

    // Signs the user out and redirects user to the logout page
    signOut() {
        oidcUserManager.signoutRedirect().catch(function (error) {
            console.error(error)
        })
    }

    // Signs the user out and redirects user to EduMS Core
    signOutDeImpersonate() {
        oidcUserManager.signoutRedirect().then(() => {
            window.location = process.env.VUE_APP_CORE_UI_URL
        }).catch(function (error) {
            console.error(error)
        })
    }

    // Gets the authenticated user
    async getUser() {
        let user = await oidcUserManager.getUser()

        return user
    }

    // Decodes the specified JWT token
    decodeJwtToken(jwtToken) {
        if (!jwtToken) {
            return null
        }

        let tokenParts = jwtToken.split('.')

        if (!tokenParts || tokenParts.length != 3) {
            return null
        }

        return JSON.parse(atob(tokenParts[1]))
    }

    // Gets the authenticated user
    async getUserId() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return null
        }

        return user.profile.sub
    }

    async getUserName() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return null
        }

        return user.profile.name
    }

    // Gets the authenticated user's roles
    async getUserRoles() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return null
        }

        let decodedAccessToken = this.decodeJwtToken(user.access_token)

        if (decodedAccessToken == null) {
            return null
        }

        let userRoles = decodedAccessToken.role

        if (!Array.isArray(userRoles)) {
            userRoles = [userRoles]
        }

        return userRoles
    }

    // Gets the authenticated user's roles
    async getUserProviderId() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return null
        }

        let decodedAccessToken = this.decodeJwtToken(user.access_token)

        if (decodedAccessToken == null) {
            return null
        }

        return decodedAccessToken['provider-id']
    }


    // Gets the authenticated user's roles
    async getUserAccountId() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return null
        }

        let decodedAccessToken = this.decodeJwtToken(user.access_token)

        if (decodedAccessToken == null) {
            return null
        }

        return decodedAccessToken['account-id']
    }

    // Indicates whether the authenticated user has the specified role
    async hasRole(role) {
        let userRoles = await this.getUserRoles()

        if (userRoles == null) {
            return false
        }

        return userRoles.filter((r) => r.toUpperCase() === role.toUpperCase()).length > 0
    }



    // Indicates whether a user has valid role to be authenticated
    async isAuthenticated(roles) {
        const currentRoles = await this.getUserRoles()

        if (currentRoles == null) return

        const hasValidRoles = roles.filter((role) => currentRoles.includes(role))

        return (hasValidRoles && hasValidRoles.length > 0)
    }


    async isAuthorizedInEduMSProvider() {
        return await this.isAuthenticated(roleAccessProviderUI)
    }

    async isAuthorizedInEduMSCore() {
        return await this.isAuthenticated(roleAccessCoreUI)
    }

    // Indicates whether the authenticated user is being impersonated
    async isUserImpersonated() {
        let user = await oidcUserManager.getUser()

        if (user == null) {
            return false
        }

        let decodedAccessToken = this.decodeJwtToken(user.access_token)

        if (decodedAccessToken == null) {
            return false
        }

        return decodedAccessToken.act != null
    }

    // Indicates whether the authenticated user is an administrator
    async isAdministrator() {
        let hasAdminRole = await this.hasRole(userAccessRoles.admin)
        let hasAdminReadOnlyRole = await this.hasRole(userAccessRoles.adminReadOnly)
        let hasProviderAdminRole = await this.hasRole(userAccessRoles.providerAdmin)

        return hasAdminRole || hasAdminReadOnlyRole || hasProviderAdminRole
    }

    async isTestUser() {
        let userName = await this.getUserName()
        return userName === 'e2e-test-admin-user@valtech.com' 
    }

    // Indicates whether the authenticated user is an administrator (for EduMS Core)
    async isCoreAdministrator() {
        let hasAdminRole = await this.hasRole(userAccessRoles.admin)
        let hasAdminReadOnlyRole = await this.hasRole(userAccessRoles.adminReadOnly)

        return hasAdminRole || hasAdminReadOnlyRole
    }

    // Indicates whether the authorized user is allowed to impersonate other users
    async isImpersonator() {
        let hasRole = await this.hasRole(userAccessRoles.impersonator)

        return hasRole
    }

    // Indicates whether the authenticated user is an account manager
    async isAccountManager() {
        let hasRole = await this.hasRole(userAccessRoles.accountManager)

        return hasRole
    }

    // Indicates whether the authenticated user is a back-office employee
    async isBackOfficeEmployee() {
        let hasRole = await this.hasRole(userAccessRoles.backofficeEmployee)

        return hasRole
    }

    // Indicates whether the authenticated user is a portfolio administrator
    async isPortfolioAdministrator() {
        let hasRole = await this.hasRole(userAccessRoles.portfolioAdministrator)

        return hasRole
    }

    // Indicates whether the authenticated user is an assortment owner
    async isAssortmentOwner() {
        let hasRole = await this.hasRole(userAccessRoles.assortmentOwner)

        return hasRole
    }

    // Indicates whether the authenticated user is an NCOI back-office employee
    async isBackOfficeNcoi() {
        let hasRole = await this.hasRole(userAccessRoles.backOfficeNcoi)

        return hasRole
    }

    async isAccountManagerCore() {
        let hasRole = await this.hasRole(userAccessRoles.accountManagerCore)

        return hasRole
    }
}