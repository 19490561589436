<template>
    <div class="c-header">
        <div class="o-layout o-layout--large">
            <div class="c-header__wrapper d-flex w-100 justify-content-between align-items-center">
                <div class="d-flex qa-edums-logo">
                    <router-link :to="{ name: 'portfolio-dashboard' }">
                        <img alt="Edums logo" class="c-header__logo" :src="edumsLogo" />
                    </router-link>
                </div>

                <div class="d-flex align-items-center">
                    <img v-if="!isAdministrator && provider.logoUrl" :src="provider.logoUrl" alt="provider-logo" class="c-header__logo mr-2 qa-header-provider-logo" />

                    <!-- User name & provider name with dropdown menu -->
                    <profile-dropdown />

                    <div v-if="!isAdministrator" :class="['c-icon c-icon__notification ml-3 qa-icon-notification', { 'has-badge': setBadge }]" @click="showNewFeatures">
                        <img class="c-notification-bell" 
                            alt="notification icon" 
                            :src="notificationIcon"
                            width="18"
                            height="20"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 
 

<script>
import { mapState } from 'vuex'
import ProfileDropdown from '@/components/atoms/ProfileDropdown' 
import UserService from '../../../../shared/services/UserService'

import edumsLogo from '../../assets/images/logo_edums.svg'
import notificationIcon from '../../assets/images/icon_bell.svg'

import { formatUtcDateTimeToLocalDate, isTodayOrBefore, isDateAfter } from '@/utils/dateTimeHelper'

export default {
    name: 'HeaderComponent',
    components: {
        ProfileDropdown
    },
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            setBadge: true,
            releaseDate: null,
            edumsLogo,
            notificationIcon
        }
    },
    computed: {
        ...mapState('providerModule', ['provider'])
    },
    async mounted() {
        this.isAdministrator = await this.userService.isAdministrator()

        if (!this.isAdministrator) {
            this.$bus.on('has-notification-badge', this.manageNotificationBadge)
            this.$bus.on('trigger-notification-badge', this.checkNewFeaturesStatus)

            this.timeout = setTimeout(() => {
                this.checkNewFeaturesStatus()
            }, 10)
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
        this.$bus.off('has-notification-badge')
        this.$bus.off('trigger-notification-badge')
    },
    methods: { 
        showNewFeatures() {
            this.$bus.emit('show-new-features', this.setBadge)
            localStorage.setItem('provider_newFeaturesViewDate', this.releaseDate)

            const htmlBody = document.getElementsByTagName('html')[0]

            if (!htmlBody.classList.contains('u-overflow-hidden')) {
                htmlBody.classList.add('u-overflow-hidden')
            } else {
                htmlBody.classList.remove('u-overflow-hidden')
            }
        },
        checkNewFeaturesStatus(newFeatures) {  
            if(!newFeatures) return

            const newFeaturesViewDate = localStorage.getItem('provider_newFeaturesViewDate')
            const notificationRead = localStorage.getItem('provider_notificationRead')

            const presentArticle = newFeatures.find(article => { 
                return newFeaturesViewDate === null ? isTodayOrBefore(article.startDate) : isDateAfter(article.startDate, newFeaturesViewDate) && isTodayOrBefore(article.startDate)
            })

            if (presentArticle && !notificationRead) {
                this.setBadge = true 
            }
        },
        manageNotificationBadge(payload) {
            this.setBadge = payload 
        }
    }
}
</script>
 