export default function(price, addLeadingEuroSign) {
    const addEuroSign = addLeadingEuroSign !== false

    if (price === 'nvt') return 'N.v.t.'

    const formattedPrice = parseFloat(price)
        .toFixed(2)
        .replace(/.00$/, '')
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

    const finalPrice = formattedPrice.indexOf(',') < 0 ? `${formattedPrice},00` : formattedPrice

    if (addEuroSign) {
        return `€ ${finalPrice}`
    }
    return finalPrice
}
