import Vue from 'vue'
import Vuex from 'vuex'

import courseModule from './modules/course'
import providerModule from './modules/provider'
import orderModule from './modules/order' 
import toastNotificationModule from './modules/toastNotifications'
// import createPersistedState from 'vuex-persistedstate'
//TODO: to check for another solution

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        courseModule,
        providerModule,
        orderModule, 
        toastNotificationModule
    },
    //plugins: [createPersistedState()],
    strict: debug
})
