<template>
    <div class="c-navigation">
        <div class="o-layout o-layout--large">
            <div class="d-flex justify-content-between flex-wrap align-items-center">
                <nav class="c-navigation__wrapper d-flex align-items-center">
                    <router-link
                        v-for="navigationItem in filteredNavigationItems"
                        :key="navigationItem.title"
                        :to="setTargetRoute(navigationItem)"
                        :class="
                            'c-navigation__link d-flex align-items-center ' +
                            navigationItem.testClass
                        "
                    >
                        {{ navigationItem.title }}
                    </router-link>
                </nav>

                <div class="d-flex align-items-center justify-content-end" :class="isAdministrator ? 'w-40' : ''"> 
                    <router-link
                        to="/support-center/"
                        :class="['c-navigation__link qa-nav-support-center', {'mr-5': isAdministrator}]"
                    >
                        {{ PROVIDER.Header.Navigation.SupportCenter }}
                    </router-link>

                    <provider-select-dropdown v-if="isAdministrator" class="c-navigation__dropdown" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import ProviderSelectDropdown from '@/components/molecules/ProviderSelectDropdown'
import { roleAccess } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'NavigationBar',
    components: {
        ProviderSelectDropdown
    },
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            isAdministrator: false,
            filteredNavigationItems: [],
            navigationItems: [
                {
                    title: PROVIDER.Header.Navigation.Portfolio,
                    routeName: 'portfolio-dashboard',
                    testClass: 'qa-nav-bar-portfolio',
                    meta: {
                        roleAccess: [
                            roleAccess.admin,
                            roleAccess.adminReadOnly,
                            roleAccess.impersonator,
                            roleAccess.providerAdmin,
                            roleAccess.portfolioAdministrator
                        ]
                    }
                },
                {
                    title: PROVIDER.Header.Navigation.Orders,
                    routeName: 'order-dashboard',
                    testClass: 'qa-nav-bar-orders',
                    meta: {
                        roleAccess: [
                            roleAccess.admin,
                            roleAccess.adminReadOnly,
                            roleAccess.impersonator,
                            roleAccess.providerAdmin,
                            roleAccess.backofficeEmployee
                        ]
                    }
                },
                {
                    title: PROVIDER.Header.Navigation.Leerhistorie,
                    routeName: 'educational-fulfillment-overview', 
                    testClass: 'qa-nav-bar-educational-fulfillment',
                    meta: {
                        providerAccess: true,
                        roleAccess: [
                            roleAccess.admin,
                            roleAccess.adminReadOnly,
                            roleAccess.impersonator,
                            roleAccess.providerAdmin,
                            roleAccess.backofficeEmployee
                        ]
                    }
                },
                {
                    title: PROVIDER.Header.Navigation.Results,
                    routeName: 'result-dashboard',
                    testClass: 'qa-nav-bar-result',
                    meta: {
                        providerAccess: true,
                        roleAccess: [
                            roleAccess.admin,
                            roleAccess.adminReadOnly,
                            roleAccess.impersonator,
                            roleAccess.providerAdmin,
                            roleAccess.accountManager
                        ]
                    }
                },
                {
                    title: PROVIDER.Header.Navigation.Leveranciersgegevens,
                    routeName: 'provider-settings-general-info-tab',
                    testClass: 'qa-nav-bar-provider-settings',
                    meta: {
                        roleAccess: [roleAccess.accountManager]
                    }
                },
                {
                    title: PROVIDER.Header.Navigation.Gebruikers,
                    routeName: 'users-overview',
                    testClass: 'qa-nav-bar-users',
                    meta: {
                        roleAccess: [roleAccess.accountManager]
                    }
                }
            ]
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
        this.filteredNavigationItems = await this.filterNavigationItems()
    },
    methods: {
        async filterNavigationItems() {
            let userRoles = await this.userService.getUserRoles()

            if (userRoles == null) {
                return
            }

            let filteredNavigationItems = this.navigationItems.reduce((acc, item) => {
                const validRole = item.meta.roleAccess.filter((role) => userRoles.includes(role))

                if (validRole.length) {
                    return [...acc, item]
                }

                return acc
            }, [])

            return filteredNavigationItems
        },
        setTargetRoute(navigationItem) {
            if (navigationItem.query) {
                return { name: navigationItem.routeName, query: navigationItem.query }
            }

            return { name: navigationItem.routeName }
        }
    }
}
</script>
