import axios from 'axios'

const CancelToken = axios.CancelToken
let cancel

export function postDeimpersonateUser(cancelPreviousRequest) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }

    const url = `${process.env.VUE_APP_AUTHORITY_URL}/api/users/deimpersonate`

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url,
            withCredentials: true,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
            .then((response) => {
                resolve(response)
            })
            .catch((response) => {
                reject(response)
            })
    })
}
export default { postDeimpersonateUser }
