<template>
    <!-- this component is created purely for redirecting purpose after provider user has logged in 
        to check if they have ever agreed on legal consent. -->
    <div></div>
</template>

<script>
import { getItemByIdNoCancel } from "@/../../shared/api/SharedClient"
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'LegalAgreementsCheck',
    data() {
        return {
            userService: new UserService(),
            userConsent: undefined
        }
    },
    watch: {
        // if provider user has NEVER agreed on our legal agreements (or in future story to check on their accepted date),
        // we push them to the legal-agreements page to show the legal agreements pop up
        userConsent(newValue) {
            if (!newValue) {
                this.$router.push({
                    name: 'legal-agreements',
                    query: { redirect: this.$route.fullPath }
                })
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getUserConsent()
        })
    },
    methods: {
        async getUserConsent() {
            try {
                let response = null

                try {
                    response = await getItemByIdNoCancel(
                        process.env.VUE_APP_IDENTITY_SERVER_URL,
                        API_CALL_URL_PATHS.userConsents,
                        await this.userService.getUserId()
                    )
                } catch (error) {
                    this.userConsent = false
                }

                if (!response) return

                this.userConsent = response.providerUi
            } catch (error) {
                console.error('Something went wrong while retrieving user consent', error)
            }
        }
    }
}
</script>