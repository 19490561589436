<template>
    <recess-dropdown class="qa-profile-dropdown">
        <template slot="dropdown-user-icon">
            <img alt="dropdown user icon"
                width="34"
                height="34"
                src="../../assets/images/user_icon.svg" 
            />
        </template>
        <template slot="dropdown-title">
            <div class="u-line-height-small qa-logout-menu">
                <div class="u-word-break">{{ userName }}</div>
                <div>{{ profileName }}</div>
            </div>
        </template>
        <template slot="dropdown-content">
            <ul class="u-list-style-none">
                <li v-if="!isAdministrator">
                    <router-link
                        to="/email-settings"
                        class="c-navigation__link d-flex align-items-center"
                    >
                        <img alt="user icon" 
                            src="../../assets/images/user-icon-2.svg" 
                            class="mr-3" 
                        />
                        <span class="qa-email-settings-link">
                            {{ PROVIDER.Header.ProfileDropdown.EmailText }}
                        </span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        to=""
                        class="d-inline-flex align-items-center"
                        @click.native.prevent="userLogout"
                    >
                        <img alt="logout icon"
                            src="../../assets/images/logout_icon.svg" 
                            class="mr-3" 
                        />
                        <span class="qa-logout-button">{{
                            PROVIDER.Header.ProfileDropdown.LogoutText
                        }}</span>
                    </router-link>
                </li>
            </ul>
        </template>
    </recess-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'ProfileDropdown',
    data() {
        return {
            PROVIDER,
            userService: new UserService(),
            isAdministrator: false,
            userName: null
        }
    },
    async mounted() {
        await this.setUserData()
    },
    computed: {
        ...mapGetters('providerModule', ['getProviderName']),
        profileName() {
            if (!this.isAdministrator) {
                return this.getProviderName
            } else {
                return 'Administrator'
            }
        }
    },
    methods: {
        async setUserData() {
            let user = await this.userService.getUser()

            if (user == null) {
                return
            }

            this.isAdministrator = await this.userService.isAdministrator()
            this.userName = user.profile.name
        },
        userLogout() {
            localStorage.removeItem('providerId')
            this.userService.signOut()
        }
    }
}
</script>
